import React, { useState } from "react"
import styles from "./OrderModal.module.scss"
import OrderModalBlock from "./OrderModalBlock"
import clsx from "clsx"
import { useTranslation } from "react-i18next"
import { checkAllowCamera } from "../../../utils/helpers"
import { setIsAllowCameraModal } from "../../../redux/slice/modals"
import { useDispatch } from "react-redux"
import QrScanModal from "../QrScanModal/QrScanModal"
import IconBtn from "../../Assets/IconBtn/IconBtn"
import { QRMobIcon } from "../../../icons/QRMobIcon"
import { useFormikContext } from "formik"

interface Props {
  el: any
  isRequired?: boolean
  isFirstQrDate?: string | null
  error?: boolean
}

const OrderModalQRBlock: React.FC<Props> = ({ el, isRequired, isFirstQrDate, error }) => {
  const { values, setFieldValue, errors, setFieldTouched, touched, setFieldError } = useFormikContext<{
    [key: string]: any
  }>()
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const dispatch = useDispatch()
  const [isQrScanModal, setQrScanModal] = useState<boolean>(false)

  const checkAllowCameraFn = async () => {
    checkAllowCamera()
      .then(() => {
        setQrScanModal(true)
      })
      .catch(() => {
        dispatch(setIsAllowCameraModal(true))
      })
  }

  return (
    <OrderModalBlock title={`${el?.name}`} isRequired={isRequired} className={styles.qrWrapBlock} error={error}>
      {(!values[el.id] && !!isFirstQrDate) || values[el.id] ? (
        <div className={styles.QRInput}>
          <input disabled value={isFirstQrDate || values[el.id] || undefined} className={`input`} />
          {/* Для удаления времени вов ремя отладки */}
          {/* <div
            onClick={() => {
              void setFieldValue(`${el.id}`, "")
            }}
          >
            DELETE
          </div> */}
          <IconBtn
            icon={<QRMobIcon />}
            className={styles.QRInput__icon}
            onClick={() => {
              checkAllowCameraFn()
            }}
          />
        </div>
      ) : (
        <>
          <div
            onClick={() => {
              checkAllowCameraFn()
            }}
            className={`${styles.qrBlock} ${!!errors?.[el?.id] && !!touched?.[el?.id] ? "error" : ""}`}
          >
            <QRMobIcon />
            {t("qr.scanQr")}
          </div>
          {touched?.[el?.id] && errors && errors?.[el.id] && errors[el.id]?.toString() !== "empty" ? (
            <span className={clsx("error-text", styles.qrWrapBlock__error)}>{errors[el.id]?.toString()}</span>
          ) : null}
        </>
      )}
      {isQrScanModal && (
        <QrScanModal
          open={isQrScanModal}
          setOpen={setQrScanModal}
          afterScaningFunc={(val: string) => {
            void setFieldValue(`${el.id}`, val)
            setQrScanModal(false)
          }}
          callbackError={() => {
            void setFieldTouched(`${el.id}`)
            setTimeout(() => {
              setFieldError(`${el.id}`, t("qr.scanningErrorQr"))
            }, 0)
            setQrScanModal(false)
          }}
        />
      )}
    </OrderModalBlock>
  )
}

export default OrderModalQRBlock
