import * as _ from 'lodash';

/**
 * Deep diff between two object-likes
 * @param  {Object} fromObject the original object
 * @param  {Object} toObject   the updated object
 * @return {Object}            a new object which represents the diff
 */
export function deepDiff(fromObject: any, toObject: any) {
    const changes: any = {};

    const buildPath = (path: any, obj: any, key: any) => {
        const origVal = _.get(obj, key);
        if (_.isUndefined(path)) {
            if (_.isArray(origVal)) {
                changes[key] = [];
            } else if (_.isObject(origVal)) {
                changes[key] = {};
            }
        } else {
            if (_.isArray(origVal)) {
                path[key] = [];
            } else if (_.isObject(origVal)) {
                path[key] = {};
            }
        }
        return [_.isUndefined(path) ? changes : path, key]
    }


    const walk = (fromObject: any, toObject: any, path?: any) => {
        for (const key of _.keys(fromObject)) {
            const objKeyPair = buildPath(path, fromObject, key);
            if (!_.has(toObject, key)) {
                objKeyPair[0][objKeyPair[1]] = { from: _.get(fromObject, key) };
            }
        }

        for (const [key, to] of _.entries(toObject)) {
            const isLast = _.has(fromObject, key);
            const objKeyPair = buildPath(path, fromObject, key);
            if (isLast) {
                const from = _.get(fromObject, key);
                if (!_.isEqual(from, to)) {
                    // if (_.isObjectLike(to) && _.isObjectLike(from)) {
                    //     walk(from, to, objKeyPair[0][objKeyPair[1]]);
                    // } else {
                        objKeyPair[0][objKeyPair[1]] = to
                    // }
                } else {
                    delete objKeyPair[0][objKeyPair[1]]
                }
            } else {
                objKeyPair[0][objKeyPair[1]] = to
            }
        }
    };

    walk(fromObject, toObject);

    return Object.keys(changes).map((key) => {
        return {
            id: key.replace("directory-", ""),
            data: changes[key]
        }
    })
}