import { FC, useEffect, useRef, useState } from "react"
import Modal from "../../Modal/Modal"
import { PatternFormat } from "react-number-format"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { authModalOpen } from "../../../redux/slice/authModal"
import { useGetSmsMutation, useLoginSmsMutation, useSetNewUserNameMutation } from "../../../redux/api/auth"
// import { updateServiceList } from "../../../redux/slice/main"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import { selectIsAllowForRegistaration } from "../../../redux/slice/auth"
import { useCheckTokenMutation } from "../../../redux/api/auth"
import clsx from "clsx"
import Button from "../../Assets/Button/Button"
import PhoneInput from "react-phone-number-input"
import "react-phone-number-input/style.css"
import { getLocale } from "../../../utils/getLocale"
import styles from "./AuthModal.module.scss"
import { DEFAULT_COUNTRY_CODE } from "../../../utils/constants"

interface Props {
  type?: string
  appendFcn?: () => void
}

const AuthModal: FC<Props> = ({ type, appendFcn }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const [getSms] = useGetSmsMutation()
  const [logined] = useLoginSmsMutation()
  const [setNewUserName] = useSetNewUserNameMutation()
  // Шаг модалки авторизации
  const [step, setStep] = useState<number>(1)
  const [errorMessage, setErorMessage] = useState<"notFound" | "blocked" | undefined>(undefined)
  const [btnPhoneDisabled, setBtnPhoneDisabled] = useState<boolean>(true)
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined)
  const [inputName, setInputName] = useState<string | undefined>(undefined)
  const [code, setCode] = useState<string | undefined>(undefined)
  const dispatch = useAppDispatch()
  const [seconds, setSeconds] = useState(0)
  const [isErrCode, setIsErrCode] = useState<number | undefined>(undefined)
  const [isShowError, setIsShowError] = useState<boolean>(false)
  const [countWithErr, setCountWithErr] = useState(null)
  const [loading, setLoading] = useState<boolean>(false)

  const [privacyLink, setPrivacyLink] = useState<string>(`https://${process.env.REACT_APP_PUBLIC_PROJECT_TYPE === 'digitask' ? 'digitask.uz' : 'staq.ru'}/terms-en.html`)

  const inp1 = useRef<any>(null)
  const inp2 = useRef<any>(null)

  const [checkToken] = useCheckTokenMutation({
    fixedCacheKey: "authCacheKey",
  })

  const currentLang = getLocale()

  const { openModal } = useSelector((state: any) => state.authModal)

  const isAllowForRegistration = useAppSelector(selectIsAllowForRegistaration)

  useEffect(() => {
    setPrivacyLink(`https://${process.env.REACT_APP_PUBLIC_PROJECT_TYPE === 'digitask' ? 'digitask.uz' : 'staq.ru'}/terms-${currentLang}.html`)
  }, [currentLang])

  useEffect(() => {
    let interval: any = null
    if (step === 2 && seconds && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((sec: number) => sec - 1)
      }, 1000)
    }
    return () => clearInterval(interval)
  }, [step, seconds])

  useEffect(() => {
    if (step === 1 && inp1?.current) {
      inp1.current?.focus()
    } else if (step === 2 && inp2?.current) {
      inp2.current?.focus()
    }
  }, [step])

  const setOpen = () => {
    dispatch(authModalOpen({ flag: false }))
    document.body.classList.remove("noscroll")
    setStep(1)
  }

  useEffect(() => {
    // TODO: что за step = 0? Такого нет
    if (openModal && type) setStep(type === "room" || type === "onlilang" ? 0 : 1)
  }, [openModal])

  const getSmsByPhone = () => {
    setLoading(true)
    setIsErrCode(undefined)
    getSms({ phoneNumber: String(phoneNumber).replace(/([()\-\s])/g, "") })
      .unwrap()
      .then((resp: any) => {
        if (resp.errorCode === "UserSmsAuthNotFound") {
          // setStep(10)
          setErorMessage("notFound")
        } else if (resp.errorCode === "is_blocked") {
          // setStep(11)
          setErorMessage("blocked")
        } else {
          // if (resp.errorCode === "SmsWasSentToTheThisNumber") {
          //   setSeconds(resp?.data?.ttl)
          // }
          setStep(2)
        }
        setSeconds(resp?.data?.ttl)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  useEffect(() => {
    if ((isErrCode === 1 || isErrCode === 2) && code && code.length === 4) setIsErrCode(undefined)
    if (isErrCode === 3 && !seconds) setIsErrCode(undefined)
  }, [code])

  const loginedWithCode = () => {
    if (step === 2) {
      if (phoneNumber) {
        if (isErrCode === 3 && phoneNumber) {
          getSmsByPhone()
          setIsErrCode(undefined)
          return
        }
        if (!code || code.length !== 4) {
          setIsErrCode(1)
          setIsShowError(true)
          return
        }
        if (!isErrCode) {
          setLoading(true)
          logined({ phoneNumber: String(phoneNumber).replace(/([()\-\s])/g, ""), code })
            .unwrap()
            .then((res) => {
              if (!res.errorCode) {
                if (isAllowForRegistration && res?.data?.user?.recently_created) {
                  setStep(3)
                } else {
                  if (appendFcn) appendFcn()
                  setOpen()
                }
              } else {
                if (res.errorCode === "InvalidCode") {
                  setIsErrCode(2)
                  setIsShowError(true)
                  setCountWithErr(res.data.attemptsLeft)
                }
                if (res.errorCode === "LimitOfAttemptsIsExceeded") {
                  setIsErrCode(3)
                  setIsShowError(true)
                  setSeconds(res?.data?.ttl || 0)
                }
                if (res.errorCode === "is_blocked") {
                  setIsErrCode(5)
                  setIsShowError(true)
                }
              }
              setLoading(false)
            })
            .then(() => {
              // // Обновление списка услуг
              // dispatch(updateServiceList(serviceList?.data ?? []))
              // getCategories()
              //   .then((result: any) => {
              //     dispatch(updateServiceList({ list: result?.data?.data ?? [] }))
              //   })
              //   .catch((err: any) => console.log(err))
            })
            .catch((err) => {
              console.error(err, "err")
              setLoading(false)
            })
        }
      }
    }
    if (step === 3) {
      if (!inputName?.length) {
        setIsErrCode(4)
        setIsShowError(true)
        return
      }
      setNewUserName({ name: inputName.trim() }).then(() => {
        checkToken()
        if (appendFcn) appendFcn()
        setOpen()
      })
    }
  }

  const onPaste = (e: any) => {
    e.stopPropagation()
    e.preventDefault()

    const clipboardText = e.clipboardData.getData("Text").replace(/([()\-\s])/g, "")
    let replaceValue = ""

    replaceValue = clipboardText
    // if (clipboardText[0] === "+") {
    //   replaceValue = clipboardText.replace(/\+/, "")
    // } else {
    //   replaceValue = clipboardText
    // }

    // if (clipboardText.length === 12) {
    //   if (clipboardText[0] === "+") {
    //     replaceValue = clipboardText.replace(/\+7/, "")
    //   }
    // } else if (clipboardText.length === 11) {
    //   if (clipboardText[0] === "8" || clipboardText[0] === "7") {
    //     // replaceValue = clipboardText
    //     replaceValue = clipboardText.slice(1, clipboardText.length)
    //   }
    // } else if (clipboardText.length === 10) {
    //   replaceValue = clipboardText
    // }

    setPhoneNumber(`${replaceValue}`)
    setBtnPhoneDisabled(replaceValue ? replaceValue.length < 5 : true)
    return false
  }

  const checkCodeValueAndErrors = (e: any) => {
    if (isErrCode) {
      if (e.target.value.length < 4) {
        setIsShowError(false)
      }
    }
    setCode(e.target.value)
  }

  const firstStepSubmit = () => {
    if (phoneNumber && !btnPhoneDisabled) {
      getSmsByPhone()
    }
    setErorMessage(undefined)
  }

  return (
    <Modal open={openModal} setOpen={setOpen} addClass={"auth"} isLogo contentClass={styles.authModal__content}>
      <div className={clsx(styles.authModal)}>
        {/*Login*/}
        {step === 1 && (
          <>
            <div className={clsx(`modal-head`, styles.authHead)}>
              <h2 className={`modal-title`}>{t("auth")}</h2>
            </div>
            <div className={`modal-text`}>{t("authText1")}</div>
            <div className={`modal-input`}>
              <div className={`input-label`}>{t("enterPhone")}</div>
              <PhoneInput
                ref={inp1}
                international
                value={phoneNumber}
                disabled={loading}
                onPaste={onPaste}
                onChange={(val) => {
                  setPhoneNumber(val)
                  setBtnPhoneDisabled(val ? val.length < 5 : true)
                }}
                onKeyDown={(e: any) => (e.key === "Enter" ? firstStepSubmit() : null)}
                defaultCountry={DEFAULT_COUNTRY_CODE}
              />
              {errorMessage && (
                <span className={`error-text`}>
                  {errorMessage === "blocked" ? t("numberIsBlocked") : t("numberNotFound")}
                </span>
              )}
            </div>
            <div className={clsx("modal-bottom", styles.authBottom)}>
              {type === "room" && (
                <Button
                  mode={"gray"}
                  size={"sm-width"}
                  txt={t("back")}
                  onClick={() => setStep(0)}
                  className={styles.btnBack}
                />
              )}
              <Button
                txt={t("next")}
                icon={"arrow"}
                className={styles.btnAuth}
                onClick={firstStepSubmit}
                disabled={btnPhoneDisabled}
                isLoading={loading}
              />
            </div>
          </>
        )}
        {/*Confirm code*/}
        {step === 2 && (
          <>
            <div className={clsx(`modal-head`, styles.authHead)}>
              <div className={`modal-title`}>{t("confirm")}</div>
            </div>
            <div className={`modal-text`}>{t("authText2")}</div>
            <div className={`modal-input ${isErrCode && isShowError ? "error" : ""}`}>
              <div className={`input-label`}>{t("enterCode")}</div>
              <PatternFormat
                format="####"
                allowEmptyFormatting
                mask=""
                placeholder="0000"
                onChange={checkCodeValueAndErrors}
                disabled={seconds === 0 || isErrCode === 3}
                getInputRef={inp2}
                onKeyDown={(e: any) => (e.key === "Enter" ? loginedWithCode() : null)}
                className={styles.input}
              />
              {isShowError && (
                <div className={`error-text`}>
                  {isErrCode === 1
                    ? t("enterCode")
                    : isErrCode === 2
                    ? `${t("incorrectCode")} ${countWithErr} ${t("afterIncorrectCode")}`
                    : isErrCode === 3
                    ? `${t("limitReachInvalCode")}`
                    : isErrCode === 5
                    ? `${t("userIsBlocked")}`
                    : ""}
                </div>
              )}
            </div>
            {seconds === 0 && (
              <>
                <div className={`${styles.authModal__codeEnd}`}>
                  <span>{t("codeTimeEnd")}</span>
                  <button
                    className={styles.authModal__resendBtn}
                    disabled={seconds !== 0}
                    onClick={() => {
                      if (phoneNumber) {
                        getSmsByPhone()
                      }
                    }}
                  >
                    {t("resendCode")}
                  </button>
                </div>
              </>
            )}
            <div className={clsx("modal-bottom", styles.authBottom)}>
              <Button
                mode={"gray"}
                size={"sm-width"}
                txt={t("back")}
                onClick={() => {
                  setStep(1)
                  setCode("")
                }}
                className={styles.btnBack}
              />
              <Button
                txt={
                  isErrCode === 3
                    ? `${t("resend")} ${
                        seconds > 0
                          ? `${Math.floor(seconds / 60)}:${seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60}`
                          : ""
                      }`
                    : `${t("next")}`
                }
                icon={isErrCode === 3 ? undefined : "arrow"}
                className={styles.btnAuth}
                onClick={loginedWithCode}
                disabled={(isErrCode && isErrCode !== 3) || (isErrCode === 3 && seconds !== 0) || loading || !code}
                isLoading={loading}
              />
            </div>
          </>
        )}
        {/* {Окно регистрации} */}
        {step === 3 && (
          <>
            <div className={clsx(`modal-head`, styles.authHead)}>
              <div className={`modal-title`}>{t("singUp")}</div>
            </div>
            <div className={`modal-text`}>{t("signUpDescr")}</div>
            <div className={`modal-input`}>
              <div className={`input-label`}>{t("yourName")}</div>
              <input
                type="text"
                value={inputName}
                onChange={(e) => {
                  setInputName(e?.target?.value)
                  isErrCode === 4 && setIsErrCode(undefined)
                }}
                placeholder={t("placeholderName")}
                className={`input ${isErrCode === 4 && "error"} ${styles.input}`}
              />
              {isErrCode === 4 ? <div className={`error-text error-text_visible`}>{t("signUpError")}</div> : <></>}
            </div>
            <div className={`modal-text`}>
              {t("signUpPrivacyText")}
              <a href={privacyLink} className={"modal-text__link"} target="__blank">{`${t("signUpPrivacyLink")}`}</a>
            </div>
            <div className={clsx("modal-bottom", styles.authBottom)}>
              <Button
                mode={"gray"}
                size={"sm-width"}
                txt={t("back")}
                onClick={() => setStep(1)}
                className={styles.btnBack}
              />
              <Button txt={t("confirmBtn")} className={styles.btnAuth} onClick={loginedWithCode} isLoading={loading} />
            </div>
          </>
        )}
        {/* Окошко ошибки */}
        {(step === 10 || step === 11) && (
          <>
            <div className={clsx(`modal-head`, styles.authHead)}>
              <div className={`modal-title`}>{step === 11 ? t("numberIsBlocked") : t("numberNotFound")}</div>
            </div>
            <div className={`modal-text`}>{step === 11 ? t("accIsBlocked") : t("authText3")}</div>
            <div className={clsx("modal-bottom", styles.authBottom, styles["authBottom--center"])}>
              <Button txt={t("back")} onClick={() => setStep(1)} mode={"gray"} />
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default AuthModal
