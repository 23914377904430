import { FC, useMemo } from "react"
import clsx from "clsx"
import styles from "./CoordinationStatusLabel.module.scss"
import { useTranslation } from "react-i18next"
import { DoubleCheckIcon } from "../../../icons/DoubleCheckIcon"
import { CrossIcon } from "../../../icons/CrossIcon"
import { ProgressCircle } from "../../../icons/ProgressCircle"

interface Props {
  className?: string
  status: "denied" | "in_progress" | "approved"
}

const CoordinationStatusLabel: FC<Props> = ({ className, status }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const addedClass = useMemo(() => {
    if (status === "approved") return "res"
    if (status === "denied") return "rej"
    if (status === "in_progress") return "load"
  }, [status])

  return (
    <div className={clsx(styles["status-label"], styles[`status-label-${addedClass}`], className)}>
      {status === "approved" && (
        <>
          <DoubleCheckIcon />
          <p>{t("approved")}</p>
        </>
      )}

      {status === "denied" && (
        <>
          <CrossIcon />
          <p>{t("rejected")}</p>
        </>
      )}

      {status === "in_progress" && (
        <>
          <ProgressCircle />
          <p>{t("coordinationIsProgress")}</p>
        </>
      )}
    </div>
  )
}

export default CoordinationStatusLabel
