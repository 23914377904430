import { useEffect } from "react"
import { useAppSelector } from "./index"
import { addActiveOrdersCount, removeActiveOrdersCount, selectUser } from "../redux/slice/auth"
import { IServiceOrderWithReview } from "../types/orderTypes"
import { useDispatch } from "react-redux"
import { decreaseCountOrdersWithoutReview, increaseCountOrdersWithoutReview } from "../redux/slice/main"
import { Channel } from "laravel-echo"

// существующие события для privateUser.${user?.id}:
// newMessageInDialog - новое сообщение
// changeMessageInDialog - редактирование сообщения
// deleteMessageInDialog - удаление сообщения
// newChatDialog - добавление нового чата
// removeChatDialog - удаление чата
// changePublicUnreadMessages - изменение непрочитанных сообщений
// changePublicCountActiveApprovals - изменение кол-ва активных согласований
// changeStatusPublicOrder - изменение статуса заявки
// updatePublicOrder - изменение заявки
// createPublicOrder - создание новой заявки
// deleteOrder - удаление заявки

const useChangeStatusOrder = () => {
  const dispatch = useDispatch()
  const user = useAppSelector(selectUser)

  useEffect(() => {
    if (!user?.id) return
    const deleteOrderBySocket = (event: IServiceOrderWithReview) => {
      if (!event?.id) return
      if (event.status === 0) {
        dispatch(removeActiveOrdersCount())
      } else {
        if (event.status === 1 && !event.hasReview) {
          dispatch(decreaseCountOrdersWithoutReview())
        }
      }
    }

    const channel: Channel = window?.Echo?.private(`privateUser.${user?.id}`)
    // .resolvePublicOrder - есть такое еще событие
    // меняем счётчик активных заявок и отзывов
    channel?.listen(".changeStatusPublicOrder", (event: IServiceOrderWithReview) => {
      if (!event?.id) return
      if (event.status === 0) {
        // если активная
        // Когда заявка создалась, но еще не была взята в работу она тоже имеет статус 0
        if (event.prev_status !== 0) {
          dispatch(addActiveOrdersCount())
          if (!event.hasReview && event.prev_status !== -1) {
            // если заявка без отзыва и прошлый статус не "Отменённая"
            dispatch(decreaseCountOrdersWithoutReview())
          }
        }
      } else {
        // если неактивная
        dispatch(removeActiveOrdersCount())
        if (event.status === 1 && !event.hasReview) {
          dispatch(increaseCountOrdersWithoutReview())
        }
      }
    })
    // создалась новая заявка
    channel?.listen(".createPublicOrder", (event: IServiceOrderWithReview) => {
      if (!event?.id) return
      dispatch(addActiveOrdersCount())
    })
    // удаление заявки
    channel?.listen(".deleteOrder", deleteOrderBySocket)

    return () => {
      channel?.stopListening(".changeStatusPublicOrder")
      channel?.stopListening(".createPublicOrder")
      channel?.stopListening(".deleteOrder", deleteOrderBySocket)
    }
  }, [user?.id])
}

export default useChangeStatusOrder
