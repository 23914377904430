import Layout from "../../components/Layout/Layout"
import ArticlesAside from "../../components/Articles/ArticlesAside/ArticlesAside"
import Articles from "../../components/Articles/Articles"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useAppSelector } from "../../hooks"
import { selectUser } from "../../redux/slice/auth"
import { useLazyGetFavoriteArticlesQuery } from "../../redux/api/articles"
import { useEffect, useState } from "react"
import { setFavoriteArticles } from "../../redux/slice/articles"
import Article from "../../components/Articles/Article/Article"
import ArticlesLayoutLoader from "../../components/LayoutLoaders/ArticlesLayoutLoader"
import useWindowSize from "../../hooks/useWindowSize"

export default function ArticlesPage() {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { isDesktop } = useWindowSize()

  const user = useAppSelector(selectUser)

  const [getFavoriteArtucles, { data: favoriteArticlesList, isLoading: isFavoriteLoading, isFetching }] =
    useLazyGetFavoriteArticlesQuery(undefined)

  const [isRequired, setRequired] = useState<boolean>(!id)

  useEffect(() => {
    getFavoriteArtucles()
  }, [])

  useEffect(() => {
    if (!isFetching && favoriteArticlesList) dispatch(setFavoriteArticles(favoriteArticlesList))
  }, [favoriteArticlesList])

  return (
    <Layout
      isHeaderMobHidden={id ? Boolean(user?.id) : true}
      mainClass={"main--md"}
      aside={<ArticlesAside />}
      isUserRequired={isRequired}
      mainLoader={!id ? <ArticlesLayoutLoader /> : undefined}
      isAsideHidden={!user?.id && !isDesktop}
      headerClickLogin={() => setRequired(true)}
    >
      {id ? (
        <Article id={id} isFavoriteLoading={isFavoriteLoading} />
      ) : (
        <Articles isFavoriteLoading={isFavoriteLoading} />
      )}
    </Layout>
  )
}
