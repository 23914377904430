/*return true if mobile, and return false if desktop*/
export function detectDevice() {
  // @ts-ignore
  const isMobile = window?.matchMedia || window?.msMatchMedia
  if (isMobile) {
    const match_mobile = isMobile("(pointer:coarse)")
    return match_mobile?.matches
  }
  return false
}

export function isRunningInStandaloneMode() {
  // @ts-ignore
  return window.matchMedia("(display-mode: standalone)").matches || window.navigator.standalone === true
}
