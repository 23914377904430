import { useEffect } from "react"
import { Html5Qrcode, Html5QrcodeResult } from "html5-qrcode"
import { Html5QrcodeError } from "html5-qrcode/core"
import { Html5QrcodeCameraScanConfig } from "html5-qrcode/src/html5-qrcode"

const qrcodeRegionId = "html5qr-code-full-region"

import * as Sentry from "@sentry/react"

interface Props {
  config?: Html5QrcodeCameraScanConfig
  qrCodeSuccessCallback?: (decodedText: string, result: Html5QrcodeResult) => void
  callbackError?: () => void
}

// Creates the configuration object for Html5QrcodeScanner.
const createConfig = (props: Html5QrcodeCameraScanConfig) => {
  const config = {} as Html5QrcodeCameraScanConfig
  if (props.fps) {
    config.fps = props.fps
  }
  if (props.qrbox) {
    config.qrbox = props.qrbox
  }
  if (props.aspectRatio) {
    config.aspectRatio = props.aspectRatio
  }
  if (props.disableFlip !== undefined) {
    config.disableFlip = props.disableFlip
  }
  return config
}

const Html5QrcodePlugin = ({ config = { fps: 15 }, qrCodeSuccessCallback, callbackError }: Props) => {
  const qrCodeErrorCallback = (decodedText: string, decodedResult: Html5QrcodeError) => {
    // console.error("err qrCodeErrorCallback: ", decodedText, decodedResult)

    const err1 = " No MultiFormat Readers were able to detect the code",
      err2 = "No barcode or QR code detected"

    if (
      decodedResult &&
      decodedResult.errorMessage &&
      !decodedResult.errorMessage.includes(err1) &&
      !decodedResult.errorMessage.includes(err2)
    ) {
      Sentry.captureException(
        Error(`Ошибка считывания QR. decodedText: ${decodedText}; decodedResult: ${JSON.stringify(decodedResult)}`),
      )
    }

    if (callbackError) callbackError()
  }

  useEffect(() => {
    const qrCodeSuccessCallbackPlugin = (decodedText: string, result: Html5QrcodeResult) => {
      console.log("qrCodeSuccessCallbackPlugin: ", decodedText, result)
      if (html5QrCode?.isScanning) {
        html5QrCode.stop().then(() => {
          html5QrCode.clear()
        })
      }
      if (qrCodeSuccessCallback) qrCodeSuccessCallback(decodedText, result)
    }
    // when component mounts
    const currentConfig = createConfig(config)
    // const verbose = props.verbose === true
    // Success callback is required.
    if (!qrCodeSuccessCallback) {
      throw "qrCodeSuccessCallback is required callback."
    }
    // const html5QrcodeScanner = new Html5QrcodeScanner(qrcodeRegionId, config, verbose)
    // html5QrcodeScanner.render(props.qrCodeSuccessCallback, props.qrCodeErrorCallback)

    // let cameraId
    // // This method will trigger user permissions
    // Html5Qrcode.getCameras()
    //   .then((devices) => {
    //     /**
    //      * devices would be an array of objects of type:
    //      * { id: "id", label: "label" }
    //      */
    //     if (devices && devices.length) {
    //       cameraId = devices[0].id
    //       // ... use this to start scanning.
    //     }
    //   })
    //   .catch((err) => {
    //     console.error("err getCameras: ", err)
    //   })
    // if (!cameraId) return

    const html5QrCode = new Html5Qrcode(qrcodeRegionId)
    html5QrCode
      .start(
        // cameraId,
        // { facingMode: "environment" },
        // { facingMode: { exact: "environment" } },
        qrcodeRegionId,
        {
          ...currentConfig,
          aspectRatio: 1.777778,
          fps: 15,
          videoConstraints: { facingMode: "environment", width: 9999 },
          qrbox: { width: 280, height: 280 },
          disableFlip: false,
        },
        qrCodeSuccessCallbackPlugin,
        qrCodeErrorCallback,
      )
      .catch((err) => {
        Sentry.captureException(Error(`Ошибка старта считывания QR. ${JSON.stringify(err)}`))
      })

    return () => {
      if (html5QrCode?.isScanning) {
        html5QrCode.stop().then(() => {
          html5QrCode.clear()
        })
      }
    }
  }, [])

  return <div id={qrcodeRegionId} />
}

export default Html5QrcodePlugin
