import { useTranslation } from "react-i18next"
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input"
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { useGetCodeByEmailMutation, useGetSmsMutation } from "../../../redux/api/auth"
import Button from "../../Assets/Button/Button"
import clsx from "clsx"
import TextInput from "../../Assets/TextInput/TextInput"
import { DEFAULT_COUNTRY_CODE, EMAIL_REGEXP } from "../../../utils/constants"
import styles from "./AuthModal2.module.scss"

const AuthModalLogin = ({
  setStep,
  setSeconds,
  phoneNumber,
  setPhoneNumber,
  isEmail,
  setIsEmail,
  email,
  setEmail,
}: {
  setStep: Dispatch<SetStateAction<number>>
  setSeconds: Dispatch<SetStateAction<number>>
  phoneNumber: string
  setPhoneNumber: Dispatch<SetStateAction<string>>
  isEmail: boolean
  setIsEmail: Dispatch<SetStateAction<boolean>>
  email: string
  setEmail: Dispatch<SetStateAction<string>>
}) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const inp1 = useRef<any>(null)

  const [getSms, { isLoading }] = useGetSmsMutation()
  const [getCode, { isLoading: isLoadingCode }] = useGetCodeByEmailMutation()

  // const [phoneNumber, setPhoneNum] = useState<string | undefined>(undefined)
  const [btnPhoneDisabled, setBtnPhoneDisabled] = useState<boolean>(true)
  const [btnEmailDisabled, setBtnEmailDisabled] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<"notFound" | "blocked" | "wasSent" | undefined>(undefined)
  // const [isErrCode, setIsErrCode] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (!inp1?.current) return
    inp1.current?.focus()
  }, [])

  const getSmsByPhone = () => {
    // setLoading(true)
    // setIsErrCode(undefined)
    getSms({ phoneNumber: String(phoneNumber).replace(/([()\-\s])/g, "") })
      .unwrap()
      .then((resp: any) => {
        if (resp.errorCode === "UserSmsAuthNotFound") {
          // setStep(10)
          setErrorMessage("notFound")
        } else if (resp.errorCode === "is_blocked") {
          // setStep(11)
          setErrorMessage("blocked")
        } else {
          // if (resp.errorCode === "SmsWasSentToTheThisNumber") {
          //   setSeconds(resp?.data?.ttl)
          // }
          setStep(2)
        }
        setSeconds(resp?.data?.ttl)
        // setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        // setLoading(false)
      })
  }

  const getCodeByEmail = () => {
    getCode({ email: email })
      .unwrap()
      .then((resp) => {
        if (resp.errorCode === "UserEmailAuthNotFound") {
          setErrorMessage("notFound")
        } else if (resp.errorCode === "is_blocked") {
          setErrorMessage("blocked")
        }
        // else if (resp.errorCode === "EmailWasSentToTheThisNumber") {
        //   setErrorMessage("wasSent")
        // }
        else {
          setStep(2)
        }
        setSeconds(resp?.data?.ttl)
      })
      .catch((err) => {
        console.log(err)
        // setLoading(false)
      })
  }

  const onPaste = (e: any) => {
    e.stopPropagation()
    e.preventDefault()

    const clipboardText = e.clipboardData.getData("Text").replace(/([()\-\s])/g, "")
    let replaceValue = ""

    replaceValue = clipboardText
    // if (clipboardText[0] === "+") {
    //   replaceValue = clipboardText.replace(/\+/, "")
    // } else {
    //   replaceValue = clipboardText
    // }

    // if (clipboardText.length === 12) {
    //   if (clipboardText[0] === "+") {
    //     replaceValue = clipboardText.replace(/\+7/, "")
    //   }
    // } else if (clipboardText.length === 11) {
    //   if (clipboardText[0] === "8" || clipboardText[0] === "7") {
    //     // replaceValue = clipboardText
    //     replaceValue = clipboardText.slice(1, clipboardText.length)
    //   }
    // } else if (clipboardText.length === 10) {
    //   replaceValue = clipboardText
    // }

    // setPhoneNum(`${replaceValue}`)
    setPhoneNumber(`${replaceValue}`)
    setBtnPhoneDisabled(replaceValue ? replaceValue.length < 5 : true)
    return false
  }

  const firstStepSubmit = () => {
    if (phoneNumber && !btnPhoneDisabled) {
      getSmsByPhone()
    }
    if (email && !btnEmailDisabled) {
      getCodeByEmail()
    }
    setErrorMessage(undefined)
  }

  useEffect(() => {
    setBtnPhoneDisabled(!isPossiblePhoneNumber(phoneNumber))
  }, [phoneNumber])

  useEffect(() => {
    setBtnEmailDisabled(!EMAIL_REGEXP.test(`${email}`))
  }, [email])

  return (
    <>
      <h2 className={styles.title}>{t("auth")}</h2>
      <p className={styles.txt}>{isEmail ? t("authTextEmail1") : t("authText1")}</p>
      <div className={clsx(`modal-input`, styles.inputWrap)}>
        <div className={`input-label`}>{isEmail ? t("enterEmail") : t("enterPhone")}</div>
        {isEmail ? (
          <TextInput
            value={email}
            onChange={(val) => {
              setEmail(`${val}`)
              setBtnEmailDisabled(!EMAIL_REGEXP.test(`${val}`))
            }}
            placeholder={"youremail@domainname"}
            className={styles["email-input"]}
          />
        ) : (
          <PhoneInput
            ref={inp1}
            international
            value={phoneNumber}
            disabled={isLoading}
            onPaste={onPaste}
            onChange={(val) => {
              setPhoneNumber(`${val}`)
            }}
            onKeyDown={(e: any) => (e.key === "Enter" ? firstStepSubmit() : null)}
            className={styles.country}
            defaultCountry={DEFAULT_COUNTRY_CODE}
          />
        )}

        {errorMessage && (
          <span className={`error-text`}>
            {errorMessage === "blocked"
              ? t("numberIsBlocked")
              : errorMessage === "wasSent"
              ? t("codeHasAlreadySent")
              : t("numberNotFound")}
          </span>
        )}
      </div>
      <div className={styles.authBottom}>
        <Button
          mode={"gray"}
          txt={isEmail ? t("loginByPhone") : t("loginByEmail")}
          onClick={() => {
            setIsEmail((prev) => !prev)
            setPhoneNumber("")
            setEmail("")
            setBtnPhoneDisabled(true)
            setBtnEmailDisabled(true)
          }}
          className={styles["change-btn"]}
        />
        <Button
          txt={t("next")}
          icon={isLoading || isLoadingCode ? undefined : "arrow"}
          className={styles.btnAuth}
          onClick={firstStepSubmit}
          disabled={(isEmail ? btnEmailDisabled : btnPhoneDisabled) || isLoading}
          isLoading={isLoading || isLoadingCode}
        />
      </div>
    </>
  )
}

export default AuthModalLogin
