import styles from "./QRScan.module.scss"
import Html5QrcodePlugin from "./Html5QrcodePlugin"
import { Html5QrcodeResult } from "html5-qrcode"
import { useLazyGetTechObjByGuidQuery } from "../../redux/api/main"
import { LINK_REGEXP } from "../../utils/constants"
import moment from "moment/moment"
import { setQRScanDate } from "../../redux/slice/object"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { Dispatch, SetStateAction } from "react"

interface Props {
  afterScaningFunc?: (val?: any) => void
  callbackError?: () => void
  setOpen: Dispatch<SetStateAction<boolean>>
}

const QRScan3 = ({ afterScaningFunc, callbackError, setOpen }: Props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [getTechObjByGuid, { isLoading }] = useLazyGetTechObjByGuidQuery()

  const onNewScanResult = (decodedText: string, result: Html5QrcodeResult) => {
    if (!decodedText) return
    if (LINK_REGEXP.test(decodedText)) {
      window.location.replace(decodedText)
    } else {
      if (isLoading) return
      try {
        getTechObjByGuid(decodedText)
          .unwrap()
          .then((res) => {
            if (!res?.data?.id) {
              if (callbackError) callbackError()
              return
            }
            const time = moment().format("DD.MM.YYYY HH:mm")
            if (afterScaningFunc) {
              afterScaningFunc(time)
            } else {
              // dispatch(setQRScanDate(time))
              sessionStorage.setItem("QRScanDate", JSON.stringify(time))
              navigate(`/object/${res.data.id}`)
              setOpen(false)
            }
          })
          .catch((err) => {
            console.error("catch error QR: ", err)
            if (callbackError) callbackError()
          })
      } catch (err) {
        console.error("catch error QR: ", err)
        if (callbackError) callbackError()
      }
    }
  }

  return (
    <div className={styles.reader}>
      <Html5QrcodePlugin qrCodeSuccessCallback={onNewScanResult} />
    </div>
  )
}

export default QRScan3
