// import imageCompression, { Options } from "browser-image-compression"
import { toBase64 } from "./helpers"
import Resizer from "react-image-file-resizer"

// npm i browser-image-compression  | npm uninstall browser-image-compression
// npm i image-conversion | npm uninstall image-conversion
// npm i react-image-file-resizer | npm uninstall react-image-file-resizer

// interface OptionsProps extends Options {
//   /*если на выходе нужен Blob*/
//   blob?: boolean
//   /*если на выходе нужен base64*/
//   base64?: boolean
// }
//
// const defaultOptions: Options = {
//   maxSizeMB: 1,
//   // Use webworker for faster compression with the help of threads
//   useWebWorker: true,
// }

// export async function compressImage(file: File, options: OptionsProps) {
//   if (!file) return null
//   try {
//     const config = { ...defaultOptions, ...options }
//     if (config?.maxSizeMB && config.maxSizeMB * 1048576 > file.size) {
//       // если размер файла в пределах допустимого
//       if (options?.blob) return await imageCompression(file, {})
//       if (options?.base64) return await toBase64(file)
//       return file
//     }
//
//     const compressedBlobFile = await imageCompression(file, config)
//     if (options?.blob) return compressedBlobFile
//     if (options?.base64) return await toBase64(compressedBlobFile)
//     // compressedFile.lastModifiedDate = new Date()
//     return new File([compressedBlobFile], file.name, {
//       type: file.type,
//       lastModified: Date.now(),
//     })
//   } catch (err) {
//     console.error("err in compress image: ", err)
//   }
// }

// export async function compressImage2(file: File, options: any) {
//   const compressedBlobFile = await compressAccurately(file, 1000).then((res) => {
//     console.log(res)
//     return res
//   })
//   return await toBase64(compressedBlobFile)
// }

export async function compressImage(file: File) {
  if (!file) return null
  try {
    if (1048576 > file.size) {
      // если размер файла в пределах допустимого
      return await toBase64(file)
    }

    const resizeFile = await new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1920,
        1080,
        "JPEG",
        90,
        0,
        (uri) => {
          resolve(uri)
        },
        "base64",
        // "blob",
      )
    })
    return resizeFile
  } catch (err) {
    console.error("err in compress image: ", err)
  }
}
