import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { getApiUrl } from "../../utils/getApiUrl"
import { getLocale } from "../../utils/getLocale"
import { deleteCookie, getCookie, setCookie } from "../../utils/cookies"
import { MAX_AGE_TOKEN_180_DAYS_IN_SECONDS, USER_REFRESH_TOKEN_SEND, USER_TOKEN_EXPIR } from "../../utils/constants"
import moment from "moment"

// https://redux-toolkit.js.org/rtk-query/api/fetchBaseQuery

/*const endpointsWithoutAuthorization = [
  'login',
  'register',
  'emailVerify',
  'resendEmailVerify',
  'setRole',
  'resetPassword',
  'createLid',
  'getCatalogTeams',
]*/

const endpointsFormData = ["createProject", "updateUser", "sendMessage", "postCreateOrder"]

const endpointsWithoutAuthorization = [
  "login",
  "register",
  "emailVerify",
  "resendEmailVerify",
  "setRole",
  "resetPassword",
  "createLid",
  "getCatalogTeams",
]

const clearDataAndRedirect = (api: any, extraOptions: any) => {
  staqBaseQuery(
    {
      url: "public/logout",
      method: "POST",
    },
    api,
    extraOptions,
  ).then(() => {
    setCookie(USER_TOKEN_EXPIR, "null", {
      expires: new Date(0),
    })
    deleteCookie(USER_REFRESH_TOKEN_SEND, { sameSite: "lax", path: process.env.NEXT_PUBLIC_ASSET_PREFIX || "/" })
    window.location.replace("/")
  })
}

const staqBaseQuery = async (args: any, api: any, extraOptions: any) => {
  /*web - админка, public - личный кабинет*/
  const defaultResult: any = await fetchBaseQuery({
    baseUrl: `${args.isWeb ? getApiUrl() + "/web/" : getApiUrl()}`,
    credentials: "include",
    prepareHeaders: (headers, { endpoint, ...api }) => {
      const currentLang = getLocale()

      if (!endpointsWithoutAuthorization.includes(endpoint)) {
        headers.set("Accept-Language", currentLang)
      }

      // headers.set('Accept', 'application/json')
      if (!endpointsFormData.includes(endpoint)) {
        headers.set("Content-Type", "application/json")
      }
      return headers
    },
  })(args, api, extraOptions)

  const tokenExpir = getCookie(USER_TOKEN_EXPIR)
  const tokenRefreshSend = getCookie(USER_REFRESH_TOKEN_SEND)
  const url = defaultResult.meta.request.url,
    isRefresh = url.includes("refresh"),
    isChat = url.includes("chat-messages"),
    isLogout = url.includes("logout") || url.includes("login")

  if (!!tokenExpir && tokenRefreshSend !== "true") {
    const momentExpir = moment.duration(moment(String(tokenExpir)).diff(moment()))
    if (momentExpir.asHours() < 8) {
      setCookie(USER_REFRESH_TOKEN_SEND, "true", {
        maxAge: MAX_AGE_TOKEN_180_DAYS_IN_SECONDS,
        sameSite: "lax",
        path: process.env.NEXT_PUBLIC_ASSET_PREFIX || "/",
      })
      setCookie(USER_TOKEN_EXPIR, new Date(moment().add(5, "d").toDate()), {
        maxAge: MAX_AGE_TOKEN_180_DAYS_IN_SECONDS,
        sameSite: "lax",
        path: process.env.NEXT_PUBLIC_ASSET_PREFIX || "/",
        expires: new Date(moment().add(180, "d").toDate()),
      })
      await staqBaseQuery(
        {
          url: "public/login/refresh",
          method: "POST",
        },
        api,
        extraOptions,
      )
        .then((resp: any) => {
          deleteCookie(USER_REFRESH_TOKEN_SEND, { sameSite: "lax", path: process.env.NEXT_PUBLIC_ASSET_PREFIX || "/" })
        })
        .catch((err: any) => {
          clearDataAndRedirect(api, extraOptions)
          return
        })
    } else {
      deleteCookie(USER_REFRESH_TOKEN_SEND, { sameSite: "lax", path: process.env.NEXT_PUBLIC_ASSET_PREFIX || "/" })
    }
  } else {
    deleteCookie(USER_REFRESH_TOKEN_SEND, { sameSite: "lax", path: process.env.NEXT_PUBLIC_ASSET_PREFIX || "/" })
  }

  if (!isLogout) {
    if (!defaultResult.data) {
      if (defaultResult.error && defaultResult.error.status === 401 && !isRefresh && !isChat) {
        clearDataAndRedirect(api, extraOptions)
      }
    }
  }

  return {
    ...defaultResult,
  }
}

export const api = createApi({
  reducerPath: "api",
  tagTypes: ["Orders"],
  baseQuery: staqBaseQuery,
  extractRehydrationInfo(action, { reducerPath }) {
    // if (action.type === HYDRATE) {
    return action?.payload?.[reducerPath]
    // }
  },
  endpoints: () => ({}),
})
