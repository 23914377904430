import { useEffect, useState } from "react"

// Хук для отслеживания видимости вкладки и фокуса окна
export const useVisibilityAndFocus = () => {
  const [isTabVisible, setIsTabVisible] = useState(true)
  const [isWindowFocused, setIsWindowFocused] = useState(true)

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabVisible(document.visibilityState === "visible")
    }

    const handleFocusBlur = () => {
      setIsWindowFocused(document.hasFocus())
    }

    document.addEventListener("visibilitychange", handleVisibilityChange)
    window.addEventListener("focus", handleFocusBlur)
    window.addEventListener("blur", handleFocusBlur)

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange)
      window.removeEventListener("focus", handleFocusBlur)
      window.removeEventListener("blur", handleFocusBlur)
    }
  }, [])

  return { isTabVisible, isWindowFocused }
}
