import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { authModalOpen } from "../../redux/slice/authModal"
import { useAppSelector } from "../../hooks"
import { selectIsLoginPending, selectUser } from "../../redux/slice/auth"
import { useNavigate } from "react-router-dom"
import LocalizedLink from "../../hoc/LocalizedLink"
import { getApiUrl } from "../../utils/getApiUrl"
import clsx from "clsx"
import { selectParams } from "../../redux/slice/params"
import IconBtn from "../Assets/IconBtn/IconBtn"
import SelectLang from "../SelectLang/SelectLang"
import styles from "./header.module.scss"
import useWindowSize from "../../hooks/useWindowSize"
import { useLogout } from "../../hooks/useLogout"
import { LANGUAGES } from "../../utils/constants"

interface Props {
  isMobHidden?: boolean
  headerClickLogin?: () => void
}

const Header: FC<Props> = ({ isMobHidden, headerClickLogin }) => {
  // const userLogin = true
  // const { t, i18n } = useTranslation("common");
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { serviceListLoading } = useAppSelector((state) => state.main)
  const user = useAppSelector(selectUser)
  const { isSearchVal, companyLogo } = useAppSelector(selectParams)
  const { isDesktop } = useWindowSize()
  const isLogin = useAppSelector(selectIsLoginPending)
  const logout = useLogout()

  const [scrolled, setScrolled] = useState<boolean>(false)
  const [isExitBtnDisabled, setExitBtnDisabled] = useState<undefined | true>(undefined)

  const setOpenLogin = () => {
    dispatch(authModalOpen({ flag: true }))
  }

  useEffect(() => {
    if (window.innerWidth <= 1199) return
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10
      if (isScrolled !== scrolled) {
        setScrolled(!scrolled)
      }
    }

    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [scrolled])

  if (isLogin) return isDesktop ? <div style={{ height: "var(--header-height)" }} /> : null

  const goToProfilePage = () => {
    if (!user?.id) return
    navigate("/profile")
  }

  return (
    <header
      className={clsx(
        "header",
        styles.header,
        (isMobHidden || isSearchVal) && styles["header--is-mob-hidden"],
        scrolled && styles["header--is-scrolled"],
      )}
    >
      <div className={clsx("header__wp", styles.header__wp, !user?.id && styles["header__not-logined"])}>
        <div className={styles.header__inner}>
          <div className={styles.header__auth}>
            {LANGUAGES?.length > 0 && (
              <>
                {isDesktop ? (
                  <SelectLang className={styles.lang} />
                ) : (
                  !serviceListLoading && <SelectLang className={styles.lang} />
                )}
              </>
            )}
            <div className={styles["fio-container"]} onClick={goToProfilePage}>
              {isDesktop && user?.id && (
                <div className={clsx(styles.header__avatar)}>
                  {user?.avatar_id ? (
                    <img src={getApiUrl() + "/web/user/avatar/" + user?.avatar_id} alt="Avatar" />
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.99999 8.8C11.5464 8.8 12.8 7.5464 12.8 6C12.8 4.4536 11.5464 3.2 9.99999 3.2C8.4536 3.2 7.19999 4.4536 7.19999 6C7.19999 7.5464 8.4536 8.8 9.99999 8.8ZM9.99999 10C12.2091 10 14 8.20914 14 6C14 3.79086 12.2091 2 9.99999 2C7.79086 2 5.99999 3.79086 5.99999 6C5.99999 8.20914 7.79086 10 9.99999 10ZM6.48357 13.2967C5.63177 14.0991 5.09999 15.2374 5.09999 16.5C5.09999 16.8314 4.83137 17.1 4.49999 17.1C4.16862 17.1 3.89999 16.8314 3.89999 16.5C3.89999 13.4072 6.4072 10.9 9.49999 10.9H10.5C13.5928 10.9 16.1 13.4072 16.1 16.5C16.1 16.8314 15.8314 17.1 15.5 17.1C15.1686 17.1 14.9 16.8314 14.9 16.5C14.9 15.2374 14.3682 14.0991 13.5164 13.2967C13.1943 13.7898 12.7708 14.2117 12.2716 14.5327C11.6038 14.9621 10.8281 15.1934 10.0342 15.1999C9.24032 15.2063 8.46087 14.9877 7.78621 14.5692C7.26235 14.2443 6.81832 13.8092 6.48357 13.2967ZM7.46194 12.5995C7.70389 12.9834 8.03063 13.3087 8.41872 13.5494C8.90062 13.8483 9.45737 14.0045 10.0244 13.9999C10.5915 13.9953 11.1456 13.83 11.6226 13.5233C11.9929 13.2852 12.3049 12.9694 12.5381 12.5995C11.9287 12.2804 11.2354 12.1 10.5 12.1H9.49999C8.76455 12.1 8.07124 12.2804 7.46194 12.5995Z"
                      />
                    </svg>
                  )}
                </div>
              )}
              {!isDesktop && (
                <>
                  {user?.id && (
                    <div className={clsx(styles.header__avatar, serviceListLoading && "selectBack")}>
                      {!serviceListLoading && (
                        <>
                          {user?.avatar_id ? (
                            <img src={getApiUrl() + "/web/user/avatar/" + user?.avatar_id} alt="Avatar" />
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9.99999 8.8C11.5464 8.8 12.8 7.5464 12.8 6C12.8 4.4536 11.5464 3.2 9.99999 3.2C8.4536 3.2 7.19999 4.4536 7.19999 6C7.19999 7.5464 8.4536 8.8 9.99999 8.8ZM9.99999 10C12.2091 10 14 8.20914 14 6C14 3.79086 12.2091 2 9.99999 2C7.79086 2 5.99999 3.79086 5.99999 6C5.99999 8.20914 7.79086 10 9.99999 10ZM6.48357 13.2967C5.63177 14.0991 5.09999 15.2374 5.09999 16.5C5.09999 16.8314 4.83137 17.1 4.49999 17.1C4.16862 17.1 3.89999 16.8314 3.89999 16.5C3.89999 13.4072 6.4072 10.9 9.49999 10.9H10.5C13.5928 10.9 16.1 13.4072 16.1 16.5C16.1 16.8314 15.8314 17.1 15.5 17.1C15.1686 17.1 14.9 16.8314 14.9 16.5C14.9 15.2374 14.3682 14.0991 13.5164 13.2967C13.1943 13.7898 12.7708 14.2117 12.2716 14.5327C11.6038 14.9621 10.8281 15.1934 10.0342 15.1999C9.24032 15.2063 8.46087 14.9877 7.78621 14.5692C7.26235 14.2443 6.81832 13.8092 6.48357 13.2967ZM7.46194 12.5995C7.70389 12.9834 8.03063 13.3087 8.41872 13.5494C8.90062 13.8483 9.45737 14.0045 10.0244 13.9999C10.5915 13.9953 11.1456 13.83 11.6226 13.5233C11.9929 13.2852 12.3049 12.9694 12.5381 12.5995C11.9287 12.2804 11.2354 12.1 10.5 12.1H9.49999C8.76455 12.1 8.07124 12.2804 7.46194 12.5995Z"
                                fill="#121318"
                              />
                            </svg>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </>
              )}

              {isDesktop ? (
                <>
                  {user?.id ? (
                    <p className={styles.header__name}>{user.name}</p>
                  ) : (
                    <button
                      className={styles.header__name}
                      onClick={() => {
                        if (headerClickLogin) headerClickLogin()
                      }}
                    >
                      {t("authorization")}
                    </button>
                  )}
                </>
              ) : (
                <>
                  {!serviceListLoading && <p className={styles.header__name}>{user?.name ?? t("login")}</p>}
                  {!user?.id && (
                    <button
                      className={styles["mob__login-btn"]}
                      onClick={() => {
                        if (headerClickLogin) headerClickLogin()
                      }}
                    >
                      {t("authorization")}
                    </button>
                  )}
                </>
              )}
            </div>

            {user?.id && (
              <IconBtn
                className={styles.exitBtn}
                icon={"exit"}
                disabled={isExitBtnDisabled}
                onClick={() => {
                  setExitBtnDisabled(true)
                  logout()
                }}
              />
            )}
          </div>

          {companyLogo.data && (
            <div className={styles.header__logo}>
              <LocalizedLink to={"/"}>
                {companyLogo.type === "image/svg+xml" ? (
                  <span
                    className={styles.header__logo__container}
                    dangerouslySetInnerHTML={{ __html: companyLogo.data }}
                  />
                ) : (
                  <img src={companyLogo.data} alt={"logo"} />
                )}
              </LocalizedLink>
            </div>
          )}
        </div>
      </div>
    </header>
  )
}

export default Header
