import Accordion from "../Assets/Accordion/Accordion"
import moment from "moment"
import { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import { IOrderReview } from "../../types/content"
import StarsRating from "../Assets/StarsRating/StarsRating"
import InformationRow from "./assets/InformationRow"
import useWindowSize from "../../hooks/useWindowSize"
import { PlusIcon } from "../../icons/PlusIcon"
import ScrollBlock from "../Assets/ScrollBlock"
import { GetUserAvatar } from "../Assets/GetUserAvatar/GetUserAvatar"
import styles from "./OrderDescripton.module.scss"
import Coordinators from "../Coordinators/Coordinators"
import { IServiceOrderWithReview } from "../../types/orderTypes"
import CoordinationStatusLabel from "../Coordinations/CoordinationStatusLabel/CoordinationStatusLabel"
import LabelInfo from "../Assets/LabelInfo/LabelInfo"
import { getApiUrl } from "../../utils/getApiUrl"
import PreviewFile from "../Assets/PreviewFile/PreviewFile"

interface IListTemplate {
  name: string
  val: string
  layout?: "active" | "history"
}

const listTemplate: IListTemplate[] = [
  {
    name: "assignees",
    val: "hasReview",
    layout: "history",
  },
  {
    name: "object",
    val: "TechnicalObject",
  },
  {
    name: "options",
    val: "options",
  },
  {
    name: "date",
    val: "created_at",
  },
  {
    name: "orderTime",
    val: "created_at",
  },
  {
    name: "orderTimeEnd",
    val: "finished_at",
  },
  {
    name: "orderNum",
    val: "number",
  },
]

interface Props {
  order: IServiceOrderWithReview
  layout?: "active" | "history" | "coordination"
  isLoading?: boolean
  review?: IOrderReview[]
}

const formatedName = (name: string) => {
  const arr = name.split(" ")

  return arr.length > 1 ? `${arr[0]} ${arr[1][0]}.` : `${arr}`
}

const OrderDescription: FC<Props> = ({ order, layout, isLoading, review }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const { isDesktop } = useWindowSize()

  const isViewCoordinationLabel = useMemo(() => {
    return layout !== "history" && !!order?.approval_status && order?.status === 0
  }, [layout, order])

  const list = useMemo(() => {
    if (isLoading || !order) return null
    return listTemplate.map((item) => {
      if (item.layout && item.layout !== layout) return null
      if (!(item.val in order)) return null
      let txt

      if (item.name === "options") {
        return order.options.map((optionItem) => {
          switch (optionItem.type) {
            case "information": {
              return <></>
              // return <InformationRow el={optionItem} key={optionItem.id} />
            }
            case "text":
            case "number":
              return (
                <li key={optionItem.id} className={styles.row}>
                  <h6 className={styles.rowTitle}>{optionItem.name}</h6>
                  <p className={styles.rowTxt}>{optionItem.value}</p>
                </li>
              )
            case "boolean":
            case "checkbox":
              return (
                <li key={optionItem.id} className={styles.row}>
                  <h6 className={styles.rowTitle}>{optionItem.name}</h6>
                  <p className={styles.rowTxt}>{optionItem.value ? t("yes") : t("no")}</p>
                </li>
              )
            case "datetime":
              return (
                <li key={optionItem.id} className={styles.row}>
                  <h6 className={styles.rowTitle}>{optionItem.name}</h6>
                  <p className={styles.rowTxt}>
                    {optionItem.value &&
                      (typeof optionItem.value === "number"
                        ? moment(Number(optionItem.value)).format("DD MMMM YYYY HH:mm")
                        : moment(optionItem.value).format("DD MMMM YYYY"))}
                  </p>
                </li>
              )
            case "directory":
            case "list": {
              const items = optionItem.items
                ? optionItem.items.map((optI: any, ind: number) => {
                    if (optionItem?.items?.length) {
                      return optI.name + (ind !== optionItem.items.length - 1 ? ", " : "")
                    }
                    return optI.name
                  })
                : null
              return (
                <li key={optionItem.id} className={styles.row}>
                  <h6 className={styles.rowTitle}>{optionItem.name}</h6>
                  <p className={styles.rowTxt}>{items}</p>
                </li>
              )
            }
            case "photo":
              return (
                <li key={optionItem.id} className={styles.row}>
                  <h6 className={styles.rowTitle}>{optionItem.name}</h6>
                  <div className={styles["photo-block"]}>
                    {optionItem.images &&
                      optionItem.images.map((el, index) => (
                        <div key={index} className={styles["photo-block__preview"]}>
                          <img src={getApiUrl() + "/public/photo/" + el} alt="" />
                        </div>
                      ))}
                  </div>
                </li>
              )
            case "attachment":
              return (
                <li key={optionItem.id} className={styles.row}>
                  <h6 className={styles.rowTitle}>{optionItem.name}</h6>
                  <div className={styles["file-block"]}>
                    {optionItem.files && optionItem.files.map((el, index) => <PreviewFile key={el.id} {...el} />)}
                  </div>
                </li>
              )
            default:
              return null
          }
        })
      }
      switch (item.name) {
        case "assignees":
          if (!order.hasReview) return null
          return (
            <div key={item.name} className={styles.assignee}>
              <div>
                <p className={styles.assignee__name}>
                  {order?.assignees[0]?.name ? formatedName(order?.assignees[0]?.name) : t("executorNotFound")}
                </p>
                {review && review?.length > 0 ? (
                  <StarsRating disabled rating={review[0].rating} />
                ) : order.score ? (
                  <StarsRating disabled rating={order.score} />
                ) : null}
              </div>

              {order.assignees?.length > 0 && (
                <GetUserAvatar
                  name={order.assignees[0].name}
                  avatar={order.assignees[0].avatar_id}
                  className={clsx(styles.assignee__avatar)}
                  size={"xmd"}
                />
              )}
            </div>
          )
        case "date":
          txt = moment(order.created_at).format("DD MMMM YYYY")
          break
        case "orderTime":
          txt = moment(order.created_at).format("kk:mm")
          break
        case "orderTimeEnd": {
          txt = order?.planed_end_date ? moment(order.planed_end_date * 1000).format("DD MMMM YYYY kk:mm") : null
          break
        }
        default:
          // @ts-ignore
          txt = order[item.val]
      }

      if (!txt) return null

      return (
        <li key={item.name} className={styles.row}>
          <h6 className={styles.rowTitle}>{t(item.name)}</h6>
          <p className={styles.rowTxt}>{txt}</p>
        </li>
      )
    })
  }, [order, review, layout, isLoading])

  if (isLoading) return <div className={clsx(styles["description--is-loading"], "skeletonBlock")} />

  return (
    <div className={styles.description}>
      {isDesktop ? (
        <ScrollBlock hideTracksWhenNotNeeded autoHeightMax={["active", "history"].includes(layout || "") ? 520 : 500}>
          <Accordion header={t("description")} className={styles["order-descr-accord"]}>
            <ul className={styles.list}>{list}</ul>
          </Accordion>
          {!order?.approvals?.length ||
          order?.approvals.every((approval: any) => approval.assignees.length === 0) ? null : (
            <Accordion
              header={t("coordination")}
              className={clsx(styles["order-descr-accord"], styles["coordination-accordion"])}
            >
              <Coordinators approveSteps={order.approvals} />
            </Accordion>
          )}
        </ScrollBlock>
      ) : (
        <div className={styles["order-descr-container"]}>
          {order?.status === -1 && (
            <LabelInfo txt={t("canceledOrder")} icon={"denied"} color={"danger"} className={styles.canceled} />
          )}
          <ul className={styles.list}>{list}</ul>
          {order?.approvals?.length ? (
            <div className={styles["coordinators-container"]}>
              <h4>
                {t("coordination")}
                {isViewCoordinationLabel && !!order?.approval_status && (
                  <CoordinationStatusLabel
                    className={styles["coordination-status-label"]}
                    status={order?.approval_status}
                  />
                )}
              </h4>
              <Coordinators approveSteps={order.approvals} />
            </div>
          ) : null}
        </div>
      )}
    </div>
  )
}

export default OrderDescription
