import CountBtn from "../Assets/CountBtn/CountBtn"
import Button from "../Assets/Button/Button"
import Label from "../Assets/Label/Label"
import clsx from "clsx"
import Paper from "../Assets/Paper/Paper"
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { declensionNumber, timeUnitsForLang, toBase64 } from "../../utils/helpers"
import { useFormikContext } from "formik"
import OptionsList from "../Modals/OrderModal/OptionsList"
import SelectObject from "../SelectObject/SelectObject"
import { IOrder } from "../../types/orderTypes"
import { useParams } from "react-router-dom"
import { useGetTechObjectQuery } from "../../redux/api/main"
import { getLocale } from "../../utils/getLocale"
import { DEFAULT_LANGUAGE } from "../../utils/constants"
import styles from "./OrderService.module.scss"
import _debounce from "lodash/debounce"
import * as Sentry from "@sentry/react"
import { isEqual } from "lodash"
import { deepDiff } from "../../utils/deepDiff"
import { useSetDraftOrderMutation } from "../../redux/api/content"

interface Props {
  data: IOrder
  setValidateIds: Dispatch<SetStateAction<any[]>>
  isLoadingSubmit?: boolean
}

const OrderServiceForm = ({ data, isLoadingSubmit, setValidateIds }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const currentLang = getLocale()
  const { object_id } = useParams()
  const localSavedValues = localStorage.getItem("savedOrder")

  const { data: techObjData, isLoading } = useGetTechObjectQuery(object_id as string, { skip: !object_id })
  const [setDraftOrder] = useSetDraftOrderMutation()

  const ref = useRef<HTMLDivElement>(null)
  const [isError, setIsError] = useState(false)
  const [initialObjName, setInitialObjName] = useState<string>("")

  const setErrorHandler = (val: boolean) => {
    setIsError(val)
  }

  const { setFieldValue, setFieldTouched, isSubmitting, errors, touched, isValidating, values } = useFormikContext<{
    [key: string]: any
  }>()

  const refOldValues = useRef(values)

  const debounceFn = useCallback(
    _debounce(async (values: any) => {
      if (Object.keys(refOldValues.current).length === Object.keys(values).length) {
        const diffValue = deepDiff(refOldValues.current, values)

        if (diffValue && Array.isArray(diffValue) && diffValue.length > 0 && !!diffValue[0]) {
          let files: any = diffValue[0]?.data[0] instanceof File ? [] : undefined
          if (files) {
            files = await Promise.all(
              diffValue[0].data.map(async (file: File) => {
                return { file: await toBase64(file), fileName: file.name }
              }),
            )
            diffValue[0].data = files
          }
          setDraftOrder({
            id: values.service_id,
            data: {
              options: diffValue,
              technical_object_id: values?.technical_object_id || "",
            },
          })
        }

        if (localSavedValues) {
          const changeOrderFilesType = async () => {
            const newValues: { [key: string]: any } = {}

            for (const key in values) {
              // Проверка на поле с фото/файлами
              // if (
              //   Array.isArray(values[key]) &&
              //   values[key].length > 0 &&
              //   (values[key][0].size || values[key][0].base64)
              // ) {
              //   const withoutBigFiles: any[] = values[key].filter((file: any) => file.size < 250000)
              //   const newFiles: any[] = []

              //   await Promise.all(
              //     withoutBigFiles.map(async (item: any) => {
              //       const file = await toBase64(item)
              //       newFiles.push({ file, name: item.name })
              //     }),
              //   )

              //   // Добавил ключ files, что бы из стореджа понимать что это файлы
              //   newValues[key] = { files: newFiles }
              // } else {
              newValues[key] = values[key]
              // }
            }
            return newValues
          }

          const changesValues = await changeOrderFilesType()

          const parsedLocalSavedValues: any[] = JSON.parse(localSavedValues)
          const ordrIndex = parsedLocalSavedValues.findIndex(
            (el) => el.service_id === values.service_id && el.technical_object_id === values.technical_object_id,
          )

          if (ordrIndex > -1) {
            parsedLocalSavedValues[ordrIndex] = changesValues
          } else {
            parsedLocalSavedValues.push(changesValues)

            if (parsedLocalSavedValues.length > 4) parsedLocalSavedValues.shift()
          }

          try {
            localStorage.setItem("savedOrder", JSON.stringify(parsedLocalSavedValues))
          } catch (err) {
            Sentry.captureException(Error("Ошибка записи в Localstore"))
          }
        }
      }

      refOldValues.current = values
    }, 500),
    [localSavedValues],
  )

  useEffect(() => {
    if (values.service_id) debounceFn(values)
  }, [values])

  useEffect(() => {
    // Проскролливать при наличии ошибок и окончании сабмиттинга и валидатинга
    if (Object.keys(errors)?.length && !isValidating && !isSubmitting) {
      const errorsEls = ref.current?.querySelector(".error")
      errorsEls?.scrollIntoView({ block: "center", behavior: "smooth" })
    }
  }, [isSubmitting, ref])

  const labelTime = useMemo(() => {
    let units = timeUnitsForLang[DEFAULT_LANGUAGE as "ru" | "en" | "th"]["minutes"]
    if (data?.execution_time_units && currentLang) {
      units =
        timeUnitsForLang[(currentLang as "ru" | "en" | "th") ?? "en"][
          data.execution_time_units as "minutes" | "hours" | "days"
        ]
    }
    const firstTime = data?.execution_time_from
    const secondTime = data?.execution_time_to
    if (!firstTime && !secondTime) return null
    if (currentLang === "ru" && data.execution_time_units) {
      let arrayUnits: string[] = []

      switch (data.execution_time_units) {
        case "hours": {
          arrayUnits =
            firstTime && secondTime
              ? ["час", "часа", "часов"]
              : (firstTime && !secondTime) || (!firstTime && secondTime)
              ? ["часа", "часов", "часов"]
              : []
          break
        }
        case "days": {
          arrayUnits =
            firstTime && secondTime
              ? ["день", "дня", "дней"]
              : (firstTime && !secondTime) || (!firstTime && secondTime)
              ? ["дня", "дней", "дней"]
              : []
          break
        }
      }
      if (arrayUnits.length) {
        units = declensionNumber(Number(secondTime), arrayUnits)
      }
    }
    let txt = ""
    if (firstTime && secondTime) {
      txt = `${firstTime}-${secondTime} ${units}`
    } else if (firstTime && !secondTime) {
      txt = `${t("from")} ${firstTime} ${units}`
    } else if (!firstTime && secondTime) {
      txt = `${t("to")} ${secondTime} ${units}`
    }
    return <Label txt={txt} className={clsx(styles.productItemNew__time)} mode={"orange"} size={"sm"} />
  }, [data, currentLang])

  useEffect(() => {
    if (!object_id || !techObjData?.name) return
    setInitialObjName(techObjData.name)
    void setFieldTouched("technical_object_id")
    void setFieldValue("technical_object_id", object_id)
  }, [object_id, techObjData])

  return (
    <>
      <Paper
        className={styles.main}
        footer={
          data?.service_action_name && (
            <>
              <div className={styles.footer}>
                {data?.is_multiorder && (
                  <CountBtn
                    onChange={(num) => {
                      void setFieldValue("count", num)
                    }}
                  />
                )}
                <Button
                  isLoading={isLoadingSubmit}
                  className={styles.submit}
                  txt={data?.service_action_name || t("order")}
                  disabled={isLoadingSubmit}
                  type={"submit"}
                />
              </div>
            </>
          )
        }
      >
        <div ref={ref}>
          <header className={styles.header}>
            <h2 className={styles.title}>{data?.name}</h2>
            {labelTime}
          </header>
          {data?.description && (
            <p className={styles.description} dangerouslySetInnerHTML={{ __html: data?.description }} />
          )}
          {isLoading ? (
            <div className={"skeletonBlock"} style={{ height: "200px" }} />
          ) : (
            <>
              {(object_id || data?.technicalObjects?.length > 0) && (
                <SelectObject
                  className={styles.selectObject}
                  data={data}
                  initialName={initialObjName}
                  error={touched?.technical_object_id && errors?.technical_object_id}
                  callbackSubmit={(el) => {
                    if (!el) return
                    void setFieldTouched("technical_object_id")
                    void setFieldValue("technical_object_id", el.id)
                  }}
                />
              )}
            </>
          )}

          {data?.options && data?.options?.length > 0 && (
            <OptionsList optionsItems={data.options} setError={setErrorHandler} setValidateIds={setValidateIds} />
          )}
        </div>
      </Paper>
    </>
  )
}

export default OrderServiceForm
