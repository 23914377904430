// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NoFindBtn_noFindBtn__VjtU9 {
  margin-top: 8px;
  height: 44px;
}
@media (min-width: 640px) {
  .NoFindBtn_noFindBtn__VjtU9 {
    height: 76px;
    margin-top: 16px;
  }
}
@media (min-width: 1200px) {
  .NoFindBtn_noFindBtn__VjtU9 {
    height: 44px;
    margin-top: 8px;
  }
}

.NoFindBtn_marginTopAuto__0MuuA {
  margin-top: auto;
}

.NoFindBtn_marginBottom__87NGc {
  margin-bottom: 78px;
}`, "",{"version":3,"sources":["webpack://./src/components/NoFindBtn/NoFindBtn.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;AACF;AACE;EAJF;IAKI,YAAA;IACA,gBAAA;EAEF;AACF;AAAE;EATF;IAUI,YAAA;IACA,eAAA;EAGF;AACF;;AAAA;EACE,gBAAA;AAGF;;AAAA;EACI,mBAAA;AAGJ","sourcesContent":[".noFindBtn {\n  margin-top: 8px;\n  height: 44px;\n\n  @media (min-width: 640px) {\n    height: 76px;\n    margin-top: 16px;\n  }\n\n  @media (min-width: 1200px) {\n    height: 44px;\n    margin-top: 8px;\n  }\n}\n\n.marginTopAuto {\n  margin-top: auto;\n}\n\n.marginBottom {\n    margin-bottom: 78px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noFindBtn": `NoFindBtn_noFindBtn__VjtU9`,
	"marginTopAuto": `NoFindBtn_marginTopAuto__0MuuA`,
	"marginBottom": `NoFindBtn_marginBottom__87NGc`
};
export default ___CSS_LOADER_EXPORT___;
