// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoModal_modal__ynfdb {
  align-items: flex-end;
  padding: 12px;
}
@media (min-width: 640px) {
  .InfoModal_modal__ynfdb {
    align-items: center;
    padding: 66px 40px 40px;
  }
}
.InfoModal_modal__ynfdb .modal__inner {
  max-width: 360px;
}
.InfoModal_modal__ynfdb .modal__body {
  min-height: 60px;
  display: flex;
}
.InfoModal_modal__ynfdb .modal__close-btn {
  top: 12px;
  right: 16px;
}
.InfoModal_modal__ynfdb .modal__title {
  padding-right: 30px;
  margin-bottom: 16px;
}
.InfoModal_modal__ynfdb .camera {
  width: 40px;
}
.InfoModal_modal__content__xk6Sg {
  display: flex;
  gap: 8px;
  width: calc(100% - 30px);
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/InfoModal/InfoModal.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,aAAA;AACF;AACE;EAJF;IAKI,mBAAA;IACA,uBAAA;EAEF;AACF;AAEM;EACE,gBAAA;AAAR;AAGM;EACE,gBAAA;EACA,aAAA;AADR;AAIM;EACE,SAAA;EACA,WAAA;AAFR;AAKM;EACE,mBAAA;EACA,mBAAA;AAHR;AAOI;EACE,WAAA;AALN;AASE;EACE,aAAA;EACA,QAAA;EACA,wBAAA;EACA,mBAAA;AAPJ","sourcesContent":[".modal {\n  align-items: flex-end;\n  padding: 12px;\n\n  @media (min-width: 640px) {\n    align-items: center;\n    padding: 66px 40px 40px;\n  }\n\n  :global {\n    .modal {\n      &__inner {\n        max-width: 360px;\n      }\n\n      &__body {\n        min-height: 60px;\n        display: flex;\n      }\n\n      &__close-btn {\n        top: 12px;\n        right: 16px;\n      }\n\n      &__title {\n        padding-right: 30px;\n        margin-bottom: 16px;\n      }\n    }\n\n    .camera {\n      width: 40px;\n    }\n  }\n\n  &__content {\n    display: flex;\n    gap: 8px;\n    width: calc(100% - 30px);\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `InfoModal_modal__ynfdb`,
	"modal__content": `InfoModal_modal__content__xk6Sg`
};
export default ___CSS_LOADER_EXPORT___;
